/* eslint-disable */
import {isValidIBAN} from "ibantools"
import moment from 'moment';
const emailReg = /^[^\/|?{}~=<>()[\]\\*,;:\%#^\s@\`'"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9.\-_]+\.)+[a-zA-Z]{2,}))$/;
// const emailReg = /^(([^\/<>()\[\]\.,;:\s@\"]+(\.[^\/<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const passwordReg = /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#\$%\^&\*\(\)_\+`\-=\{\}\|\[\]\\:";'<>\?,\.\/])(?=.{8,})/;
// this credit card regexp passed test with all main types of credit cards generated on http://www.getcreditcardnumbers.com/
const creditCardReg = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
const creditCardReg16 = /^\d{4}[\s\-]?\d{4}[\s\-]?\d{4}[\s\-]?\d{4}$/;
const creditCardReg1622 = /^\d{2}[\s\-]?\d{2}$/;
const creditExpDateReg = /\b(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})\b/;
const creditCvvReg = /^[0-9]{3}$/;
const letterReg = /^[a-zA-Z]*$/;
const whiteSpaceReg = /\s+/;
const checkValidBtcWallet = /^([1]{1}[a-zA-Z0-9]{26,33})|^([3]{1}[a-zA-Z0-9]{26,33})|^([bc1]{3}[a-zA-Z0-9]{24,31})/;
const checkValidEthWallet = /^[0x]{2}[a,b,c,d,e,f,x,A,B,C,D,E,F,X,0-9]{24,42}/;
const checkValidUsdtWallet = /^([1]{1}[a-zA-Z0-9]{25,41})|^([3]{1}[a-zA-Z0-9]{25,41})|^([0x]{2}[a-zA-Z0-9]{24,40})/;
const checkValidLtcWallet = /^([L]{1}[a-zA-Z0-9]{25,34})|^([M]{1}[a-zA-Z0-9]{25,34})|^([ltc1]{4}[a-zA-Z0-9]{22,31})/;
const checkValidBnbWallet = /^([0x]{2}[a-zA-Z0-9]{24,40})|^([bnb]{3}[a-zA-Z0-9]{23,39})/;
const checkValidAdaWallet = /^([Ae2]{3}[a-zA-Z0-9]{23,39})|^([DdzFF]{5}[a-zA-Z0-9]{21,37})|^([addr1]{5}[a-zA-Z0-9]{21,37})/;
const checkValidDogeWallet = /^[D]{1}[a-zA-Z0-9]{25,33}/;
const ibanReg = /^[A-Z0-9]{1,32}$/;
const checkValidTroneWallet = /^[T]{1}[a-zA-Z0-9]{25,33}/;

const getCheckValidBtcWallet = [ v => (v ? checkValidBtcWallet.test(v) || 'Wrong address format' : true) ]
const getCheckValidEthWallet = [ v => (v ? checkValidEthWallet.test(v) || 'Wrong address format' : true) ]
const getCheckValidUsdtWallet = [ v => (v ? checkValidUsdtWallet.test(v) || 'Wrong address format' : true) ]
const getCheckValidLtcWallet = [ v => (v ? checkValidLtcWallet.test(v) || 'Wrong address format' : true) ]
const getCheckValidBnbWallet = [ v => (v ? checkValidBnbWallet.test(v) || 'Wrong address format' : true) ]
const getCheckValidAdaWallet = [ v => (v ? checkValidAdaWallet.test(v) || 'Wrong address format' : true) ]
const getCheckValidDogeWallet = [ v => (v ? checkValidDogeWallet.test(v) || 'Wrong address format' : true) ]
const getCheckValidTroneWallet = [ v => (v ? checkValidTroneWallet.test(v) || 'Wrong address format' : true) ]
const checkSpecialSymbolSwiftBic = /^[A-Z0-9]{8}$|^[A-Z0-9]{11}$/;
const checkSpecialSymbol = /^[a-zA-Z0-9]/;
const checkOnlyLetters = /[a-zA-Z][^\[0-9\]\%/\\&\?\,\;~:!+!№#<>="{}@\[\]\$_\^*)(]*$/;
const checkOnlyKirilic = /^[a-zA-Z]/
const checkOnlyKirilicAndOnlyLetters = /[a-zA-Z][^\[0-9\]\%/\\&\?\,\;~:!+!№#<>="{}@\[\]\$_\^*)(]*$/;
const checkOnlyNumbers = /^[0-9]/;

const email = [ v => (v ? emailReg.test(v) || 'Email should be valid' : true) ];

const ibanRules = [ v => (v ? ibanReg.test(v) || 'Wrong IBAN format or not valid symbol or value must be max 32 characters' : true) ];

// const swiftRules = [ v => (v ? swiftReg.test(v) || 'Value must be 8 or 11 characters or not valid symbol' : true) ];
const getCheckSpecialSymbolSwiftBic = [ v => (v ? checkSpecialSymbolSwiftBic.test(v) || 'Value must be 8 or 11 characters or not valid symbol' : true) ];
const getCheckSpecialSymbol = [ v => (v ? checkSpecialSymbol.test(v) || 'Not valid symbol' : true) ];
const getCheckOnlyLetters = [ v => (v ? checkOnlyLetters.test(v) || 'Value must be letters' : true) ];
const getCheckOnlyNumbers = [ v => (v ? checkOnlyNumbers.test(v) || 'Value must be numbers' : true) ];
const getCheckOnlyKirilic = [ v => (v ? checkOnlyKirilic.test(v) || 'Only latin letters are allowed' : true) ];
const getCheckOnlyKirilicAndOnlyLetters = [ v => (v ? checkOnlyKirilicAndOnlyLetters.test(v) || 'Only latin letters are allowed and without number' : true) ];
// const getCheckOnlyLettersName = [ v => (v ? checkOnlyLettersName.test(v) || 'Value must be letters' : true) ];

const password = [
  v =>
    v
      ? whiteSpaceReg.test(v)
        ? 'The password must not contain spaces'
        : passwordReg.test(v) ||
        'The password must be no less than 8 characters long, contain a capital letter, a special symbol and a number'
      : true,
];

const Luhn = (card) => {
  let checksum = 0;
  const cardnumbers = card.replaceAll(' ', '').split('').map(Number);
  for (const [index, num] of cardnumbers.entries()) {
    if (index % 2 === 0) {
      let buffer = num * 2;
      buffer > 9 ? checksum += buffer - 9 : checksum += buffer;
    }
    else {
      checksum += num;
    }
  }
  return checksum % 10 === 0 ? true : false;
}

const luhnRules = [
  v =>
    v
      ? Luhn(v)
        || 'Card number is not valid'
      : true,
];

const expireDate = (date) => {

  let expireMonth = true
  let expireYear = true

  if(date.split('/')[0] > 12) expireMonth = false
  if(date.split('/')[1] < moment().format( 'YY' ) && date.split('/')[0] < moment().format( 'MM' )) expireMonth = false
  if(date.split('/')[1] < moment().format( 'YY' )) expireYear = false

  return expireMonth && expireYear;
}

const checkExpireDate = [
  v =>
    v
      ? expireDate(v)
      || 'Expire date is not valid'
      : true,
];


const firstName = [
  v => (v ? v.length > 2 || 'Name must be at least 3 characters' : true),
  v => (v ? v.length <= 20 || 'Name must be less than 20 characters' : true),
];

const tempPassword = [ v => (v ? v.length > 7 || 'Password must contain at least 8 symbols' : true) ];

const lastName = [
  v => (v ? v.length > 2 || 'Name must be at least 3 characters' : true),
  v => (v ? v.length <= 20 || 'Name must be less than 20 characters' : true),
];

const required = [ v => !!v || 'This field is required' ];

const requiredBoolRadio = [ v => typeof v === 'boolean' || 'This field is required' ];

const creditCard = [ v => (v ? creditCardReg.test(v) || 'Credit card number is not valid' : true) ];

const creditCard16 = [ v => (v ? creditCardReg16.test(v) || 'Credit card number is not valid' : true) ];

const creditExpDate = [ v => (v ? creditExpDateReg.test(v) || 'Expiration date is not valid' : true) ];

const creditCvv = [ v => (v ? creditCvvReg.test(v) || 'CVV code is not valid' : true) ];

const letters = [ v => (v ? letterReg.test(v) || 'This field is not valid' : true) ];

const ibanRulesPackage = [ v=> v ? isValidIBAN(v) || "IBAN is not valid" : true ]

export default {
  luhnRules,
  email,
  letters,
  password,
  lastName,
  required,
  requiredBoolRadio,
  creditCvv,
  firstName,
  creditCard,
  creditCard16,
  creditExpDate,
  tempPassword,
  ibanRules,
  checkExpireDate,
  getCheckOnlyKirilicAndOnlyLetters,
  // swiftRules,
  getCheckValidBtcWallet,
  getCheckValidEthWallet,
  getCheckValidUsdtWallet,
  getCheckValidLtcWallet,
  getCheckValidBnbWallet,
  getCheckValidAdaWallet,
  getCheckValidDogeWallet,
  getCheckValidTroneWallet,
  getCheckSpecialSymbol,
  getCheckSpecialSymbolSwiftBic,
  getCheckOnlyLetters,
  getCheckOnlyNumbers,
  getCheckOnlyKirilic,
  ibanRulesPackage
};
